<template>
  <nav class="c-topnav__nav">
    <div class="c-topnav__bar">
      <a class="c-topnav__home" href="/" v-html="logo"></a>
      <ul class="c-topnav__menu">
        <li v-for="item in nav">
          <a
            :href="item.url ? item.url : '#' + item.slug"
            @click="toggleActive(item.slug)"
            class="c-topnav__a"
            :aria-controls="item.pages ? `topnav__panel` : undefined"
            :aria-expanded="activePanel == item.slug ? 'true' : undefined"
          >
            {{ item?.title }}
            <svg v-if="item.pages" class="c-topnav__disclosure">
              <use xlink:href="/images/icons/system.svg#disclosure"></use>
            </svg>
          </a>
        </li>
      </ul>
      <div class="c-topnav__ctas">
        <a
          v-for="(btn, index) in cta"
          class="c-button --size:-1 --withiconright"
          :class="{ '--purple': index == cta.length - 1 }"
          :href="btn.url"
        >
          {{ btn.title }}
          <svg>
            <use xlink:href="/images/icons/system.svg#arrow"></use>
          </svg>
        </a>
      </div>
      <button
        aria-label="show menu"
        class="c-topnav__toggler"
        :aria-expanded="mobileOpen"
        @click="mobileOpen = !mobileOpen"
      >
        <svg aria-hidden="true" viewbox="0 0 24 24">
          <rect class="r1" height="2" rx="0.25" width="24" x="0" y="11"></rect>
          <rect class="r2" height="2" rx="0.25" width="24" x="0" y="11"></rect>
        </svg>
      </button>
    </div>
  </nav>
  <div class="c-topnav__panel" v-if="panel && !isMobile" id="topnav__panel">
    <div class="c-topnav__panelleft">
      <div class="c-topnav__panelcontents">
        <h4 class="c-topnav__panelcrumb">{{ panel.title }}</h4>
        <ul class="c-topnav__pages">
          <li v-for="item in panel.pages">
            <a :href="item.url"
              ><img
                :src="item.icon ?? '/images/icons/hazy/help.svg'"
                alt=""
                class="c-topnav__pageicon"
              />
              <div>
                <span>{{ item.title }}</span>
              </div></a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="c-topnav__panelright">
      <div class="c-topnav__panelcontents">
        <template v-if="panel.secondary?.pages || panel.secondary?.latest">
          <h4 class="c-topnav__panelcrumb">{{ panel.secondary.title }}</h4>
          <ul class="c-topnav__latests" v-if="panel.secondary.latest">
            <li v-for="page in panel.secondary.latest">
              <a :href="page.url">
                <div class="c-topnav__cover"><img :src="page.cover" /></div>
                <div>
                  <span>{{ page.title }}</span>
                </div>
              </a>
            </li>
          </ul>
          <ul class="c-topnav__pages" v-if="panel.secondary.pages">
            <li v-for="item in panel.secondary.pages">
              <a :href="item.url"
                ><img
                  :src="item.icon ?? '/images/icons/hazy/help.svg'"
                  alt=""
                  class="c-topnav__pageicon"
                />
                <div>
                  <span>{{ item.title }}</span>
                </div></a
              >
            </li>
          </ul>
        </template>
        <template v-else
          ><h3 class="c-topnav__paneltitle">{{ panel.secondary.headline }}</h3>
          <div class="c-topnav__paneldesc" v-html="panel.secondary.intro"></div>
        </template>
      </div>
    </div>
  </div>
  <div v-if="isMobile && mobileOpen" class="c-topnav__mobile">
    <nav class="c-topnav__level1">
      <ul>
        <li v-for="(item, key) in nav">
          <a
            :href="item.url ? item.url : '#sub' + key"
            @click="mobileSubOpen[key] = !mobileSubOpen[key]"
            :aria-expanded="item.pages ? mobileSubOpen[key] : undefined"
          >
            <svg>
              <use
                v-if="item.pages"
                xlink:href="/images/icons/system.svg#disclosure"
              ></use>
              <use v-else xlink:href="/images/icons/system.svg#arrow"></use>
            </svg>
            {{ item?.title }}
          </a>
          <ul v-if="item.pages && !!mobileSubOpen[key]">
            <li v-for="sub in item.pages">
              <a :href="sub.url">
                <svg>
                  <use xlink:href="/images/icons/system.svg#arrow"></use>
                </svg>
                {{ sub.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useMediaQuery } from "@vueuse/core";

const collapsePoint = getComputedStyle(
  document.documentElement
).getPropertyValue("--topnav-collapse");

const isMobile = useMediaQuery(`(max-width: ${collapsePoint})`);

const props = defineProps({
  cta: Object,
  nav: Object,
  logo: String,
});

const activePanel = ref("");
const mobileOpen = ref(false);
const mobileSubOpen = reactive(Object.keys(props.nav).map(() => false));

const toggleActive = (section) => {
  activePanel.value == section
    ? (activePanel.value = "")
    : (activePanel.value = section);
};
const enSmallen = () => {
  // console.log("enSmallen");
  activePanel.value = "";
};
const enLargen = () => {
  // console.log("enLargen");
  activePanel.value = "";
};
const panel = computed(() =>
  props.nav.find((p) => p.slug == activePanel.value)
);
onMounted(() => {
  const widthListener = (e) => (!e.matches ? enLargen() : enSmallen());
  var collapsePoint = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--topnav-collapse");
  matchMedia(`(max-width: ${collapsePoint})`).addListener(widthListener);
});
</script>

<style lang="scss"></style>
