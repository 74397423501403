<template>
  <div ref="observerWrapper">
    <vue-vimeo-player
      ref="player"
      v-if="!prefersReducedMotion && !isBadBrowser"
      v-bind="mergedProps"
      @ready="onReady"
      @pause="onPause"
      @play="onPlay"
      class="c-background-video"
      :class="{ '--ready': ready }"
      :style="aspectRatioStyle"
    />
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import Bowser from "bowser";
export default {
  components: { vueVimeoPlayer },
  props: {
    videoID: {
      type: String,
    },
    videoUrl: {
      type: String,
    },
    width: { type: Number },
    height: { type: Number },
    options: { type: Object },
  },
  data: () => ({
    /**
     * https://github.com/vimeo/player.js/#embed-options
     */
    defaultOptions: {
      muted: true,
      background: true,
      controls: false,
      autoplay: true,
      loop: true,
      byline: false,
      dnt: true,
      title: false,
      portrait: false,
    },
    ready: false,
    observer: null,
  }),
  computed: {
    mergedProps() {
      return {
        ...this.$props,
        options: { ...this.$props.options, ...this.defaultOptions },
      };
    },
    aspectRatioStyle() {
      if (this.width > 0 && this.height > 0) {
        return {
          "--video-width": this.width,
          "--video-height": this.height,
        };
      }
    },
    prefersReducedMotion() {
      return window.matchMedia("(prefers-reduced-motion: reduce)").matches;
    },
    isBadBrowser() {
      const browser = Bowser.getParser(window.navigator.userAgent);
      // console.log(
      //   browser
      // );
      return (
        browser.getBrowser().name == "Safari" &&
        browser.getBrowser().version.split(".")[0] < 16
      );
    },
  },
  methods: {
    onReady(player) {
      // console.log(player);
      this.ready = true;
    },
    onPause(event) {
      // console.log("pause event: ", event);
    },
    onPlay(event) {
      // console.log("play event: ", event);
    },
    play() {
      this.$refs.player?.play();
    },
    pause() {
      this.$refs.player?.pause();
    },
    createObserver() {
      const options = {
        threshold: 0,
        root: null,
        rootMargin: "0px",
      };

      this.observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          this.play();
          console.log(this.videoUrl, "intersection true");
        } else {
          this.pause();
          console.log(this.videoUrl, "intersection false", entry);
        }
      }, options);

      this.observer.observe(this.$refs.observerWrapper);
    },
  },
  mounted() {
    // still unreliable :(
    // this.createObserver();
  },
  unmounted() {
    // this.observer.disconnect();
  },
};
</script>

<style lang="scss">
.vue-app\:background-video {
  height: 100%;
  position: absolute;
  inset: 0;
}
.c-background-video {
  position: absolute;
  inset: 0;
  overflow: hidden;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  --video-width: 16;
  --video-height: 9;

  iframe {
    // fill parent container as if object-fit: cover
    position: absolute;
    top: 50%;
    left: 50%;
    width: max(calc(100vh * var(--video-width) / var(--video-height)), 100vw);
    height: max(calc(100vw * var(--video-height) / var(--video-width)), 100vh);
    // z-index: -1;
    transform: translateX(-50%) translateY(-50%);
  }
  &.--ready {
    opacity: 1;
  }
}
</style>
