import Flickity from "flickity";

export default document.addEventListener("DOMContentLoaded", function() {
  const teamTabs = document.querySelectorAll(".c-teamtabs a");
  const teamCards = document.querySelectorAll(".c-teamcards");

  var setTab = function(newindex) {
    for (let i = 0; i < teamTabs.length; i++) {
      teamTabs[i].removeAttribute("aria-selected");
    }
    teamTabs[newindex].setAttribute("aria-selected", "true");
  };
  if (teamCards.length && teamTabs.length) {
    const teamFlkty = new Flickity(".c-teamcards", {
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      on: {
        ready: function() {
          setTab(this.selectedIndex);
        }
      }
    });

    teamTabs.forEach(tab =>
      tab.addEventListener("click", event => {
        event.preventDefault();
        var index = tab.dataset.loop;
        teamFlkty.select(index);
        setTab(index);
      })
    );

    teamFlkty.on("change", function() {
      setTab(teamFlkty.selectedIndex);
    });
  }
});
