// or anything that wants togglin
document.addEventListener("DOMContentLoaded", function () {
  let faqs = document.querySelectorAll(
    ".c-faq__button, .l-pricing-compare__togglebutton"
  );
  if (faqs) {
    Array.from(faqs).forEach((el) => {
      el.addEventListener("click", function (e) {
        console.log(e, el, this);

        var content = document.querySelector(
          "#" + this.getAttribute("aria-controls")
        );

        this.setAttribute(
          "aria-expanded",
          this.getAttribute("aria-expanded") !== "true"
        );

        content.setAttribute(
          "aria-hidden",
          content.getAttribute("aria-hidden") !== "true"
        );
      });
    });
  }
});
