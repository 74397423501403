import { createApp } from "vue";
import Cookies from "js-cookie";

const newsletter = createApp({
  data() {
    return {
      error: false,
      success: false,
      name: "",
    };
  },
  computed: {
    firstname() {
      return this.name.split(" ")[0] || this.name;
    },
    lastname() {
      let lastname = this.name.split(" ");
      lastname.shift();
      lastname = lastname.join(" ");
      return lastname;
    },
    buttonLabel() {
      if (this.error) return "Error!";
      if (this.success) return "Subscribed!";
      return "Subscribe";
    },
  },
  methods: {
    subscribe($event) {
      // https://legacydocs.hubspot.com/docs/methods/forms/submit_form
      let portalId = "26104610";
      let formId = "c84381fa-ee3f-4d3a-86e4-8f501fdf85ed";

      let url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

      if (this.success || this.error) return;
      var data = {
        submittedAt: new Date().getTime(),
        fields: [],
        context: {
          hutk: Cookies.get("hubspotutk"),
          pageUri: document.location.href,
          pageName: document.title,
        },
      };
      var formData = new FormData($event.target);
      for (var key of formData.keys()) {
        let objectTypeId = $event.target.querySelector(`input[name=${key}]`)
          .dataset.objectTypeId;
        if (objectTypeId)
          data.fields.push({
            objectTypeId: objectTypeId,
            name: key,
            value: formData.get(key),
          });
      }

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("Success:", data);
          this.showSuccess();
        })
        .catch((error) => {
          // console.error("Error:", error);
          this.showError(error);
        });
    },
    showError(errorMsg) {
      this.error = true;
    },
    showSuccess() {
      this.success = true;
    },
  },
});

newsletter.mount("#vue-newsletter");
