import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default document.addEventListener("DOMContentLoaded", function () {
  let links = gsap.utils.toArray([
    ".c-solutioncases__tabs a",
    ".c-casecsi__links a",
  ]);
  links.forEach((a) => {
    // console.log(a.parentElement.getBoundingClientRect().top);
    // let start = a.parentNode.classList.contains("c-casecsi__links")
    //   ? `top top+=${a.parentElement.getBoundingClientRect().top}px`
    //   : "top top";
    let element = document.querySelector(a.getAttribute("href")),
      linkST = ScrollTrigger.create({
        trigger: element,
        start: "top top",
      });
    ScrollTrigger.create({
      trigger: element,
      start: "top center",
      end: "bottom center",
      onToggle: (self) => self.isActive && setActive(a),
    });
    // this smoothScroll thing is not including scroll-margin or other fixed/sticky top things
    // a.addEventListener("click", (e) => {
    //   e.preventDefault();
    //   gsap.to(window, {
    //     duration: 0.5,
    //     scrollTo: linkST.start,
    //     overwrite: "auto",
    //   });
    // });
  });

  function setActive(link) {
    links.forEach((el) => el.removeAttribute("aria-selected"));
    link.setAttribute("aria-selected", true);
  }
});
