document.addEventListener("DOMContentLoaded", function () {
  var wrappers = document.querySelectorAll("[data-hrefs-target]");
  wrappers.forEach((el) => {
    var target = el.dataset.hrefsTarget || "_blank";
    var links = el.querySelectorAll("a[href^='http']:not([target]");
    links.forEach((a) => {
      if (!a.classList.contains("c-ctablock")) a.setAttribute("target", target);
    });
  });
});
