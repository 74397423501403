// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss";

import { createApp } from "vue";
// import "./background-video";
import "./hubspot-form.js";
import cookieInit from "@hazy/hazy-ui/js/components/cookiebanner.js";
// import "./topnav";
import "./faqs";
import "./external-links";
import "./newsletter";
import "./syntax-format";
import "./responsive-diagrams";
import "./solutioncases";
import "./team-modal";
import "./flickity";
import TopNav from "./top-nav.vue";
import BackgroundVideo from "./background-video.vue";
import TabScroller from "./tab-scroller.vue";
import ToolTip from "@hazy/hazy-ui/js/components/tool-tip.vue";
import SummaryCard from "@hazy/hazy-ui/js/components/summary-card.vue";
import SchemaTypes from "@hazy/hazy-ui/js/components/schema-types.vue";
import SampleTable from "@hazy/hazy-ui/js/components/sample-table.vue";
import MarketplaceModel from "./marketplace-model.vue";
import * as Sentry from "@sentry/browser";
import "lite-youtube-embed/src/lite-yt-embed.js";

Sentry.init({
  dsn: "https://9ae89768dd3425dc36b7ab74ffb83747@o4506099442843648.ingest.sentry.io/4506105712082944",
  release: "dotcom@2.3.12",
  // integrations: [
  //   new Sentry.BrowserTracing({
  //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //     // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   }),
  //   new Sentry.Replay(),
  // ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, // Capture 100% of the transactions
  // // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // debug: true,
  tracingOrigins: ["*"],
});

import lightboxes from "@hazy/hazy-ui/js/components/lightbox.js";
document.addEventListener("DOMContentLoaded", function () {
  lightboxes();
});

document.addEventListener("DOMContentLoaded", function () {
  function initVueRoots(el) {
    const app = createApp({
      components: {
        TopNav,
        BackgroundVideo,
        TabScroller,
        SummaryCard,
        MarketplaceModel,
      },
    });
    app.config.globalProperties.assetPath = "/";
    const rootComponent = app.mount(el);
  }
  (function initVue() {
    // add id or class beginning with #vue-app where needed to initialize vue
    // unless you want special options, in which case do it manually elsewhere, see vue-hubspot-form for example
    const vueEls = document.querySelectorAll(
      "[id^='vue-app'], [class^='vue-app']"
    );
    return Array.from(vueEls).map(initVueRoots);
  })();
});

cookieInit();
