<template>
  <SchemaTypes v-if="sampleSchema" :sample-schema="sampleSchema" />
  <SampleTable v-if="sampleSchema" :sample-schema="sampleSchema" />
  <TableDag v-if="relationshipMap" :relationship-map-data="relationshipMap" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import SchemaTypes from "@hazy/hazy-ui/js/components/schema-types.vue";
import SampleTable from "@hazy/hazy-ui/js/components/sample-table.vue";
const TableDag = defineAsyncComponent(() =>
  import("@hazy/hazy-ui/js/components/table-dag.vue")
);
export default {
  components: { SchemaTypes, SampleTable, TableDag },
  props: {
    sampleSchema: {
      type: Object,
      required: true,
    },
    relationshipMap: {
      type: Object,
    },
  },
};
</script>
