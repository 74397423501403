import Flickity from "flickity";

Flickity.prototype._createResizeClass = function () {
  this.element.classList.add("flickity-resize");
};

Flickity.createMethods.push("_createResizeClass");

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function () {
  this.element.classList.remove("flickity-resize");
  resize.call(this);
  this.element.classList.add("flickity-resize");
};

const flickityEls = [
  {
    root: ".c-testimonials",
    cells: ".c-testimonials__flickity",
    nav: ".c-testimonials__tabs a",
    options: {
      pageDots: false,
      prevNextButtons: true,
      wrapAround: true,
      arrowShape:
        "M50 75L54.4219 70.5781L36.9687 53.125L75 53.125L75 46.875L36.9687 46.875L54.4219 29.4219L50 25L25 50L50 75Z",
    },
  },
  {
    root: ".l-careerstories",
    cells: ".l-careerstories__flickity",
    nav: ".l-careerstories__navdots a",
    options: { pageDots: false, prevNextButtons: false, wrapAround: true },
  },
];

export default document.addEventListener("DOMContentLoaded", function () {
  var setTab = function (els, newindex) {
    for (let i = 0; i < els.length; i++) {
      els[i].removeAttribute("aria-selected");
    }
    els[newindex]?.setAttribute("aria-selected", "true");
  };

  const Flktys = [];
  flickityEls.forEach((f) => {
    const rootEl = document.querySelectorAll(f.root);
    rootEl.forEach((ff, index) => {
      const cells = ff.querySelector(f.cells);
      const nav = ff.querySelectorAll(f.nav);

      Flktys[index] = new Flickity(cells, {
        ...f.options,
        ...{
          on: {
            ready: function () {
              setTab(nav, this.selectedIndex);
            },
          },
        },
      });
      nav.forEach((tab) =>
        tab.addEventListener("click", (event) => {
          event.preventDefault();
          var tabindex = tab.dataset.loop;
          Flktys[index].select(tabindex);
          setTab(nav, tabindex);
        })
      );

      Flktys[index].on("change", function () {
        setTab(nav, Flktys[index].selectedIndex);
      });
    });
  });

  const careersGalleryEl = document.querySelector(".c-careers-gallery");
  if (careersGalleryEl) {
    const GalleryFlickity = new Flickity(careersGalleryEl, {
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 2500,
    });
  }

  const featurecarousel = document.querySelector(".c-featurecarousel");
  if (featurecarousel) {
    const FeaturesFlickity = new Flickity(featurecarousel, {
      wrapAround: true,
      prevNextButtons: false,
      pageDots: true,
      autoPlay: 2500,
      adaptiveHeight: true,
      // cellAlign: "left",
    });
  }
});
