<template>
  <div>
    <nav class="c-tabs__scrollwrapper">
      <button
        class="c-tabs__scrollbtn left"
        v-show="overflowLeft > 0"
        @click="scrollTo(0)"
      >
        &larr;
      </button>
      <ul
        role="tablist"
        class="c-tabs"
        @scroll="overFlowCheck($event.target)"
        ref="scrollwrapper"
      >
        <slot></slot>
      </ul>
      <button
        class="c-tabs__scrollbtn right"
        v-show="overflowRight < overflow"
        @click="scrollTo(overflow)"
      >
        &rarr;
      </button>
    </nav>
  </div>
</template>

<script>
/**
 * just the scrolly buit of hazy-tabs for statically render li's with URLs
 */
import { ref, reactive, provide, onMounted, watchEffect, toRefs } from "vue";

export default {
  emits: ["changed", "clicked"],

  setup(props, context) {
    const state = reactive({
      overflow: 0,
      overflowLeft: 0,
      overflowRight: 0,
    });

    const scrollTo = (offset) => {
      scrollwrapper.value.scrollTo({
        top: 0,
        left: offset,
        behavior: "smooth",
      });
    };

    const overFlowCheck = (el) => {
      if (el.scrollWidth > el.clientWidth) {
        state.overflow = el.scrollWidth;
        state.overflowLeft = el.scrollLeft;
        state.overflowRight = el.clientWidth + el.scrollLeft;
      } else {
        state.overflow = 0;
        state.overflowLeft = 0;
        state.overflowRight = 0;
      }
    };

    const scrollwrapper = ref(null);
    let resizeobserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        overFlowCheck(entry.target);
      }
    });

    onMounted(() => {
      resizeobserver.observe(scrollwrapper.value);
      overFlowCheck(scrollwrapper.value);
    });

    return {
      ...toRefs(state),
      resizeobserver,
      scrollwrapper,
      overFlowCheck,
      scrollTo,
    };
  },
};
</script>
