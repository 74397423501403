// svg conversion notes:
// illustrator fonts do not match our @font-face - need to add these in!
// use unique ID for els if inlining.

document.addEventListener("DOMContentLoaded", function() {
  var componentsSvg = document.getElementById("components-svg");

  if (componentsSvg) {
    var mediaQueryList = window.matchMedia("(max-width: 40rem)");
    var panels = componentsSvg.querySelector('[data-name="explain-panels"]');

    function screenTest(e) {
      if (e.matches) {
        componentsSvg.setAttribute("viewBox", "0 0 1160 2200");
        panels.style.display = "none";
      } else {
        componentsSvg.setAttribute("viewBox", "0 0 2000 2200");
        panels.style.display = "block";
      }
    }
    screenTest(mediaQueryList);
    mediaQueryList.addListener(screenTest);
  }
});
