import { createApp, defineComponent } from "vue";
import Cookies from "js-cookie";
import { ref } from "vue";
import { createGlobalState } from "@vueuse/core";

export const useGlobalState = createGlobalState(() => {
  const recaptchaResponse = ref(null);
  return { recaptchaResponse };
});

const HubspotForm = defineComponent({
  template: "#vue-hubspot-form-template",
  setup() {
    const { recaptchaResponse } = useGlobalState();
    return { recaptchaResponse };
  },
  data() {
    return {
      errors: [],
      success: false,
      recaptchaResponse: null,
      email: null,
      urlParams: null,
    };
  },
  props: {
    portalId: {
      type: [String, Number],
    },
    guid: {
      type: [String, Number],
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    /**
     * If provided, will include a recaptcha v2 checkbox.
     * n.b. this is NOT the Hubspot captcha option, only for demo provisioning
     */
    recaptchaV2Key: {
      type: String,
    },
    provisionEndpoint: {
      type: String,
    },
  },
  methods: {
    subscribe($event) {
      // https://legacydocs.hubspot.com/docs/methods/forms/submit_form

      // I'm sure it would be tidier to v-model the fields, but using formData direct 'works'
      // it may have been a petite-vue thing?

      let url = `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.guid}`;

      if (this.success || this.error) return;
      var data = {
        submittedAt: new Date().getTime(),
        fields: [],
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: "Text that gives consent to process",
            communications: [],
          },
        },
        context: {
          hutk: Cookies.get("hubspotutk"),
          pageUri: document.location.href,
          pageName: document.title,
        },
      };
      var formData = new FormData($event.target);
      for (var key of formData.keys()) {
        let dataset = $event.target.querySelector(`[name=${key}]`)?.dataset;

        if (dataset?.objectTypeId)
          data.fields.push({
            objectTypeId: dataset.objectTypeId,
            name: key,
            value: formData.get(key),
          });

        if (dataset?.typeId)
          data.legalConsentOptions.consent.communications.push({
            subscriptionTypeId: parseInt(dataset.typeId),
            text: key,
            value: formData.get(key) == "true",
          });
      }
      console.log(data);
      this.email = data.fields?.find((f) => f.name == "email")?.value;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          if (data.status == "error") {
            this.showError(data.message);
            data.errors.forEach((e) => this.showError(e.message));
          } else {
            if (!this.provisionEndpoint || this.urlParams.get("no_provision")) {
              this.showSuccess();
            } else this.provision();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.showError(error);
        });
    },
    provision() {
      fetch(this.provisionEndpoint, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: this.email,
          "g-recaptcha-response": this.recaptchaResponse,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "error") {
            this.showError(data.message);
            data.errors?.forEach((e) => this.showError(e.message));
          } else this.showSuccess();
        })
        .catch((error) => {
          console.error("Error:", error);
          this.showError(error);
        });
    },
    showError(errorMsg) {
      console.log("Error:", errorMsg);
      this.success = false;
      this.errors.push(errorMsg);
      if (typeof gtag === "function") {
        gtag("event", "exception", {
          description: errorMsg,
        });
      }
    },
    showSuccess() {
      this.success = true;
      this.errors = [];
      if (typeof gtag === "function") {
        gtag("event", "hubspot-submit-success");
      }
    },
  },
  mounted() {
    if (!!this.recaptchaV2Key) {
      // do some tricks to make sure we are ready for the recaptcha callback
      const onloadCallback = () => {
        grecaptcha.render("g-recaptcha", {
          sitekey: this.recaptchaV2Key,
        });
      };
      window.onloadCallback = onloadCallback;

      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
      );
      document.head.appendChild(recaptchaScript);
    }

    // there may be utm_ params in the url, so set them as hidden fields
    this.urlParams = new URLSearchParams(window.location.search);
    this.urlParams.forEach((value, key) => {
      const hiddenField = document.querySelector(
        "input[type=hidden][name=" + key + "]"
      );
      if (hiddenField) {
        hiddenField.value = value;
      }
    });
  },
});

const HubspotApp = createApp({
  components: { HubspotForm },
});
HubspotApp.config.compilerOptions.delimiters = ["${", "}"];
HubspotApp.mount("#vue-hubspot-form");

const onCaptcha = function (response) {
  const { recaptchaResponse } = useGlobalState();
  recaptchaResponse.value = response;
  // console.log("recaptchaResponse", recaptchaResponse.value);
};
window.onCaptcha = onCaptcha;
